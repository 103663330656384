<template>
  <div class="text-center">
    <v-dialog
      v-model="shower"
      width="800"
      persistent
      scrollable
      v-if="resolutionScreen >= 500"
    >
      <v-card>
        <v-card-title
          >{{ $t("expiredrightclick.setexpireddate") }}<v-spacer></v-spacer>
          <v-icon :color="color.theme" class="pr-2">mdi-file-clock</v-icon>
        </v-card-title>
        <v-divider></v-divider>
        <v-card-text style="height:520px">
          <v-layout justify-center row wrap>
            <v-flex xs10 lg10 class="pt-2">
              <v-switch
                :disabled="checkbtnexfile"
                class="ml-5"
                v-model="switchset"
                :label="$t('expiredrightclick.settingexpiration')"
                @change="fn_cancelexfile(switchset)"
              ></v-switch>
            </v-flex>
          </v-layout>
          <!-- วันหมดอายุ -->
          <v-layout justify-center row wrap>
            <v-flex xs4 lg4 class="pt-2" v-if="filedata.status_expired === '3'">
              <p class="mb-0">{{ $t("expiredrightclick.choosedate") }}</p>
            </v-flex>
            <v-flex
              xs4
              lg4
              class="pt-2"
              v-else-if="filedata.status_expired !== '3'"
            >
              <p class="mb-0">{{ $t("expiredrightclick.setexpire") }}</p>
            </v-flex>
            <v-flex xs5 lg5>
              <v-dialog
                ref="dialogSetexpiredfile"
                v-model="modelsetexpiredfile"
                :return-value.sync="setexpiredfile"
                persistent
                full-width
                width="290px"
                :color="color.theme"
              >
                <template v-slot:activator="{ on }">
                  <v-text-field
                    clearable
                    @click:clear="clearbtn"
                    :color="color.theme"
                    v-model="setexpiredfile"
                    @click:prepend="modelsetexpiredfile = true"
                    prepend-icon="event"
                    v-on="on"
                    readonly
                    outlined
                    dense
                    class="my-hint-style pa-1 pt-0 pb-0"
                  ></v-text-field>
                </template>
                <v-date-picker
                  v-model="setexpiredfile"
                  scrollable
                  :locale="$t('default')"
                  :min="datenow"
                  :color="color.theme"
                  @input="$refs.dialogSetexpiredfile.save(setexpiredfile)"
                >
                  <v-spacer></v-spacer>
                  <v-btn
                    dark
                    color="error"
                    @click.stop="modelsetexpiredfile = false"
                    >{{
                      $t("myinboxPage.conditionOptional.closefromdateupload")
                    }}</v-btn
                  >
                </v-date-picker>
              </v-dialog>
            </v-flex>
          </v-layout>
          <!-- วันก่อนหมดอายุ -->
          <v-layout justify-center row wrap>
            <v-flex xs4 lg4 class="pt-1" v-if="filedata.status_expired === '3'">
              <p class="mb-0">
                {{ $t("expiredrightclick.choosebeforeexpiretiondate") }}
              </p>
            </v-flex>
            <v-flex
              xs4
              lg4
              class="pt-2"
              v-else-if="
                filedata.warning_expiration_time !== '-' ||
                  filedata.warning_expiration_time !== '00000000' ||
                  filedata.warning_expiration_time !== '0000000' ||
                  filedata.warning_expiration_time === '00000000000000'
              "
            >
              <p class="mb-0">{{ $t("expiredrightclick.setbeforeexpire") }}</p>
            </v-flex>
            <v-flex xs5 lg5>
              <v-dialog
                ref="dialogSetbeforeexpiredfile"
                v-model="modelsetbeforeexpiredfile"
                :return-value.sync="setbeforeexpiredfile"
                persistent
                full-width
                width="290px"
                :color="color.theme"
              >
                <template v-slot:activator="{ on }">
                  <v-text-field
                    clearable
                    :color="color.theme"
                    v-model="setbeforeexpiredfile"
                    @click:prepend="modelsetbeforeexpiredfile = true"
                    prepend-icon="event"
                    v-on="on"
                    readonly
                    outlined
                    dense
                    class="my-hint-style pa-1 pt-0 pb-0"
                    :disabled="
                      setexpiredfile === '' ||
                        setexpiredfile === null ||
                        setexpiredfile === '-'
                    "
                  ></v-text-field>
                </template>
                <v-date-picker
                  v-model="setbeforeexpiredfile"
                  scrollable
                  :locale="$t('default')"
                  :max="fn_datebefore(setexpiredfile)"
                  :min="datenow"
                  :color="color.theme"
                  @input="
                    $refs.dialogSetbeforeexpiredfile.save(setbeforeexpiredfile)
                  "
                >
                  <v-spacer></v-spacer>
                  <v-btn
                    dark
                    color="error"
                    @click.stop="modelsetbeforeexpiredfile = false"
                    >{{
                      $t("myinboxPage.conditionOptional.closefromdateupload")
                    }}</v-btn
                  >
                </v-date-picker>
              </v-dialog>
            </v-flex>
          </v-layout>

          <v-layout justify-center row wrap>
            <v-flex xs9 lg9 class="pt-2" v-if="filedata.status_expired !== '3'">
              {{ $t("expiredrightclick.expireddate") }}
              <v-chip color="error" class="ml-4">{{
                fn_showdatetime(time)
              }}</v-chip>
            </v-flex>
          </v-layout>
          <v-layout justify-center row wrap>
            <v-flex
              xs9
              lg9
              class="pt-2"
              v-show="
                filedata.warning_expiration_time !== '-' &&
                  filedata.warning_expiration_time !== '0000000' &&
                  filedata.warning_expiration_time !== '00000000' &&
                  filedata.warning_expiration_time !== '000000' &&
                  filedata.warning_expiration_time !== '00000000000000'
              "
            >
              {{ $t("expiredrightclick.beforeexpireddate") }}
              <v-chip color="error" class="ml-4">{{
                fn_showdatetime(timebeforeexpiration)
              }}</v-chip>
            </v-flex>
          </v-layout>
          <!-- แจ้งเตือนผ่านmail -->
          <v-layout justify-center row wrap>
            <v-flex xs10 lg10 class="pt-2">
              <v-checkbox
                v-model="alertbymail"
                :label="$t('expiredrightclick.alert_email')"
                :disabled="
                  setbeforeexpiredfile === '' ||
                    setbeforeexpiredfile === null ||
                    setbeforeexpiredfile === '-'
                "
                @change="handleCheckboxChange"
              ></v-checkbox>
            </v-flex>
            <v-flex xs10 lg10 v-if="alertbymail == true">
              <v-textarea
                id="v-textarea-email"
                :disabled="
                  setbeforeexpiredfile === '' ||
                    setbeforeexpiredfile === null ||
                    setbeforeexpiredfile === '-'
                "
                v-model="alertemail"
                autocomplete="email"
                outlined
                label="Email"
                auto-grow
                rows="2"
                :hint="$t('expiredrightclick.condition')"
                style="line-height:26px"
                persistent-hint
                :error-messages="emailError"
                @input="$v.alertemail.$touch()"
                @blur="$v.alertemail.$touch()"
              ></v-textarea>
            </v-flex>
          </v-layout>
          <!-- แจ้งเตือนผ่านOneChat -->
          <v-layout justify-center row wrap v-if="dataAccountActive.type === 'Business'">
            <v-flex xs10 lg10 class="pt-2">
              <v-checkbox
                v-model="alertbychat"
                :label="$t('expiredrightclick.alert_onechat')"
                :disabled="
                  setbeforeexpiredfile === '' ||
                    setbeforeexpiredfile === null ||
                    setbeforeexpiredfile === '-'
                "
              ></v-checkbox>
            </v-flex>
            <v-flex xs10 lg10 v-if="alertbychat == true">
              <v-card
                :disabled="
                  setbeforeexpiredfile === '' ||
                    setbeforeexpiredfile === null ||
                    setbeforeexpiredfile === '-'
                "
              >
                <v-card-title
                  >One Chat<v-spacer></v-spacer>
                  <v-icon :color="color.theme" dense dark size="35"
                    >mdi-chat</v-icon
                  >
                </v-card-title>
                <v-divider></v-divider>
                <v-tabs :color="color.theme">
                  <v-tab :color="color.theme">{{
                    $t("expiredrightclick.friend")
                  }}</v-tab>
                </v-tabs>
                <v-card-text style="height: 550px;">
                  <br />
                  <div v-if="havefriend === true">
                    <div v-if="loadingfriends === true" class="text-center">
                      <v-progress-circular
                        :size="50"
                        :color="color.theme"
                        indeterminate
                      ></v-progress-circular>
                    </div>
                    <div v-else>
                      <v-list
                        nav
                        dense
                        shaped
                        style="max-height:450px;"
                        class="overflow-y-auto"
                      >
                        <v-list-item-group :color="color.theme">
                          <v-list-item
                            v-for="item in permission"
                            :key="item.oneid_user_id"
                          >
                            <template>
                              <v-list-item-content>
                                <v-list-item-title
                                  v-if="$t('default') === 'th'"
                                >
                                  {{
                                    item.full_name_th === null ||
                                    item.full_name_th === ""
                                      ? "ไม่มีชื่อ"
                                      : item.full_name_th
                                  }}
                                </v-list-item-title>
                                <v-list-item-title v-else>
                                  {{
                                    item.full_name_en === null ||
                                    item.full_name_en === ""
                                      ? "NoName"
                                      : item.full_name_en
                                  }}
                                </v-list-item-title>
                              </v-list-item-content>
                              <v-list-item-action>
                                <v-checkbox
                                  v-model="checkfriend"
                                  :value="item"
                                  :color="color.theme"
                                  :disabled="item.permission !== 'N'"
                                  :indeterminate="item.permission !== 'N'"
                                ></v-checkbox>
                              </v-list-item-action>
                            </template>
                          </v-list-item>
                          <scroll-loader
                            class="pa-0"
                            :loader-method="fn_getuser"
                            :loader-disable="disablescrolling === true"
                          ></scroll-loader>
                        </v-list-item-group>
                      </v-list>
                    </div>
                  </div>
                  <div v-else class="text-center py-12">
                    <v-icon size="100">mdi-emoticon-sad-outline</v-icon>
                    <h2 style="font-weight: 300">
                      {{ $t("sentFileOnechat.nofriend") }}
                    </h2>
                  </div>
                  <br />
                </v-card-text>
              </v-card>
            </v-flex>
          </v-layout>
          <br />
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="red" outlined @click="$emit('closedialog'), (page = 1)">
            {{ $t("uploadfolder.buttonclose") }}
          </v-btn>
          <v-btn
            :color="color.theme"
            dark
            :loading="processdelete"
            @click="checksetexpired()"
            v-if="filedata.status_expired === '3'"
          >
            {{ $t("checkpassword.setok") }}
          </v-btn>
          <v-btn
            :color="color.theme"
            dark
            @click="checksetexpired()"
            :loading="processdelete"
            v-else-if="filedata.status_expired !== '3'"
          >
            {{ $t("checkpassword.edit") }}
          </v-btn>
        </v-card-actions>
        <v-progress-linear
          :active="processdelete"
          indeterminate
          color="green"
        ></v-progress-linear>
      </v-card>
    </v-dialog>
    <v-dialog
      v-else
      v-model="shower"
      :max-width="maxWidthOnMobile"
      content-class="rounded-lg"
      persistent
      scrollable
    >
      <v-card>
        <v-card-text class="py-5">
          <v-layout :style="'color:' + color.theme" class="mb-4">
            <v-flex d-flex justify-center>
              <h3>{{ $t("expiredrightclick.setexpireddate") }}</h3>
            </v-flex>
            <v-flex xs1 d-flex justify-end>
              <v-icon :color="color.theme" @click="$emit('closedialog')"
                >close</v-icon
              >
            </v-flex>
          </v-layout>
          <!-- วันหมดอายุ -->
          <v-layout>
            <v-flex>
              <p class="mb-2" v-if="filedata.status_expired === '3'">
                {{ $t("expiredrightclick.choosedate") }}
              </p>
              <p class="mb-2" v-else-if="filedata.status_expired !== '3'">
                {{ $t("expiredrightclick.setexpire") }}
              </p>
              <v-dialog
                ref="dialogSetexpiredfile"
                v-model="modelsetexpiredfile"
                :return-value.sync="setexpiredfile"
                persistent
                full-width
                width="290px"
                :color="color.theme"
              >
                <template v-slot:activator="{ on }">
                  <v-text-field
                    clearable
                    @click:clear="clearbtn"
                    :color="color.theme"
                    v-model="setexpiredfile"
                    @click:prepend="modelsetexpiredfile = true"
                    append-icon="event"
                    v-on="on"
                    readonly
                    outlined
                    dense
                    class="my-hint-style pt-0 pb-0"
                  ></v-text-field>
                </template>
                <v-date-picker
                  v-model="setexpiredfile"
                  scrollable
                  :locale="$t('default')"
                  :min="datenow"
                  :color="color.theme"
                  @input="$refs.dialogSetexpiredfile.save(setexpiredfile)"
                >
                  <v-spacer></v-spacer>
                  <v-btn
                    dark
                    color="error"
                    @click.stop="modelsetexpiredfile = false"
                    >{{
                      $t("myinboxPage.conditionOptional.closefromdateupload")
                    }}</v-btn
                  >
                </v-date-picker>
              </v-dialog>
            </v-flex>
          </v-layout>
          <!-- วันก่อนหมดอายุ -->
          <v-layout>
            <v-flex>
              <p class="mb-2" v-if="filedata.status_expired === '3'">
                {{ $t("expiredrightclick.choosebeforeexpiretiondate") }}
              </p>
              <p
                class="mb-2"
                v-else-if="
                  filedata.warning_expiration_time !== '-' ||
                    filedata.warning_expiration_time !== '00000000' ||
                    filedata.warning_expiration_time !== '0000000' ||
                    filedata.warning_expiration_time === '00000000000000'
                "
              >
                {{ $t("expiredrightclick.setbeforeexpire") }}
              </p>
              <v-dialog
                ref="dialogSetbeforeexpiredfile"
                v-model="modelsetbeforeexpiredfile"
                :return-value.sync="setbeforeexpiredfile"
                persistent
                full-width
                width="290px"
                :color="color.theme"
              >
                <template v-slot:activator="{ on }">
                  <v-text-field
                    clearable
                    :color="color.theme"
                    v-model="setbeforeexpiredfile"
                    @click:prepend="modelsetbeforeexpiredfile = true"
                    append-icon="event"
                    v-on="on"
                    readonly
                    outlined
                    dense
                    class="my-hint-style pt-0 pb-0"
                    :disabled="
                      setexpiredfile === '' ||
                        setexpiredfile === null ||
                        setexpiredfile === '-'
                    "
                  ></v-text-field>
                </template>
                <v-date-picker
                  v-model="setbeforeexpiredfile"
                  scrollable
                  :locale="$t('default')"
                  :max="fn_datebefore(setexpiredfile)"
                  :min="datenow"
                  :color="color.theme"
                  @input="
                    $refs.dialogSetbeforeexpiredfile.save(setbeforeexpiredfile)
                  "
                >
                  <v-spacer></v-spacer>
                  <v-btn
                    dark
                    color="error"
                    @click.stop="modelsetbeforeexpiredfile = false"
                    >{{
                      $t("myinboxPage.conditionOptional.closefromdateupload")
                    }}</v-btn
                  >
                </v-date-picker>
              </v-dialog>
            </v-flex>
          </v-layout>
          <v-divider></v-divider>
          <!-- แจ้งเตือนผ่านmail -->
          <v-layout justify-center row wrap class="mx-0">
            <v-flex>
              <v-checkbox
                v-model="alertbymail"
                :label="$t('expiredrightclick.alert_email')"
                :disabled="
                  setbeforeexpiredfile === '' ||
                    setbeforeexpiredfile === null ||
                    setbeforeexpiredfile === '-'
                "
              ></v-checkbox>
            </v-flex>
            <v-flex v-if="alertbymail == true">
              <v-textarea
                id="v-textarea-email"
                :disabled="
                  setbeforeexpiredfile === '' ||
                    setbeforeexpiredfile === null ||
                    setbeforeexpiredfile === '-'
                "
                v-model="alertemail"
                autocomplete="email"
                outlined
                label="Email"
                auto-grow
                rows="1"
                :hint="$t('expiredrightclick.condition')"
                style="line-height:26px"
                persistent-hint
                :error-messages="emailError"
                @input="$v.alertemail.$touch()"
                @blur="$v.alertemail.$touch()"
              ></v-textarea>
            </v-flex>
          </v-layout>
          <!-- แจ้งเตือนผ่านOneChat -->
          <v-layout justify-center row wrap class="mx-0">
            <v-flex>
              <v-checkbox
                class="mt-0"
                v-model="alertbychat"
                :label="$t('expiredrightclick.alert_onechat')"
                :disabled="
                  setbeforeexpiredfile === '' ||
                    setbeforeexpiredfile === null ||
                    setbeforeexpiredfile === '-'
                "
              ></v-checkbox>
            </v-flex>
            <v-flex v-if="alertbychat == true" class="mb-4">
              <v-card
                :disabled="
                  setbeforeexpiredfile === '' ||
                    setbeforeexpiredfile === null ||
                    setbeforeexpiredfile === '-'
                "
              >
                <v-card-title
                  >One Chat<v-spacer></v-spacer>
                  <v-icon :color="color.theme" dense dark size="35"
                    >mdi-chat</v-icon
                  >
                </v-card-title>
                <v-divider></v-divider>
                <v-tabs :color="color.theme">
                  <v-tab :color="color.theme">{{
                    $t("expiredrightclick.friend")
                  }}</v-tab>
                </v-tabs>
                <v-card-text>
                  <div v-if="havefriend === true">
                    <div v-if="loadingfriends === true" class="text-center">
                      <v-progress-circular
                        :size="50"
                        :color="color.theme"
                        indeterminate
                      ></v-progress-circular>
                    </div>
                    <div v-else>
                      <v-list
                        nav
                        dense
                        shaped
                        style="max-height:450px;"
                        class="overflow-y-auto"
                      >
                        <v-list-item-group :color="color.theme">
                          <v-list-item
                            v-for="item in permission"
                            :key="item.oneid_user_id"
                          >
                            <template>
                              <v-list-item-content>
                                <v-list-item-title
                                  v-if="$t('default') === 'th'"
                                >
                                  {{
                                    item.full_name_th === null ||
                                    item.full_name_th === ""
                                      ? "ไม่มีชื่อ"
                                      : item.full_name_th
                                  }}
                                </v-list-item-title>
                                <v-list-item-title v-else>
                                  {{
                                    item.full_name_en === null ||
                                    item.full_name_en === ""
                                      ? "NoName"
                                      : item.full_name_en
                                  }}
                                </v-list-item-title>
                              </v-list-item-content>
                              <v-list-item-action>
                                <v-checkbox
                                  v-model="checkfriend"
                                  :value="item"
                                  :color="color.theme"
                                  :disabled="item.permission !== 'N'"
                                  :indeterminate="item.permission !== 'N'"
                                ></v-checkbox>
                              </v-list-item-action>
                            </template>
                          </v-list-item>
                          <scroll-loader
                            class="pa-0"
                            :loader-method="fn_getuser"
                            :loader-disable="disablescrolling === true"
                          ></scroll-loader>
                        </v-list-item-group>
                      </v-list>
                    </div>
                  </div>
                  <div v-else class="text-center py-12">
                    <v-icon size="100">mdi-emoticon-sad-outline</v-icon>
                    <h2 style="font-weight: 300">
                      {{ $t("sentFileOnechat.nofriend") }}
                    </h2>
                  </div>
                </v-card-text>
              </v-card>
            </v-flex>
          </v-layout>
          <!-- ปุ่มปิด/แก้ไข -->
          <v-layout>
            <v-flex xs6>
              <v-btn
                color="red"
                outlined
                block
                @click="$emit('closedialog'), (page = 1)"
              >
                {{ $t("uploadfolder.buttonclose") }}
              </v-btn>
            </v-flex>
            <v-flex xs6 class="ml-4">
              <v-btn
                :color="color.theme"
                dark
                block
                :loading="processdelete"
                @click="checksetexpired()"
                v-if="filedata.status_expired === '3'"
              >
                {{ $t("checkpassword.setok") }}
              </v-btn>
              <v-btn
                :color="color.theme"
                dark
                block
                @click="checksetexpired()"
                :loading="processdelete"
                v-else-if="filedata.status_expired !== '3'"
              >
                {{ $t("checkpassword.edit") }}
              </v-btn>
            </v-flex>
          </v-layout>
        </v-card-text>
      </v-card>
    </v-dialog>
    <dialogcancelsettingexpired
      :show="opendialogunsetting"
      @closedialog="opendialogunsetting = false"
      :filedata="filedata"
      @reload="exitreload()"
      @closedialogunset="close_dialogsetting()"
      @resetbutton="switchset = true"
    ></dialogcancelsettingexpired>
  </div>
</template>
<script>
import { mapState, mapGetters } from "vuex";
import Swal from "sweetalert2/dist/sweetalert2.js";
import "sweetalert2/src/sweetalert2.scss";
import format from "date-fns/format";
import InfiniteLoading from "vue-infinite-loading";
import gbfGenerate from "@/globalFunctions/generateAuthorize";
import { validationMixin } from "vuelidate";
import { required, requiredIf } from "vuelidate/lib/validators";

const emailList = (value) => {
  const emails = value.split(',').map(email => email.trim());
  for (const email of emails) {
    if (!(/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email))) {
      return false
    }
  }
  return true
}

const dialogcancelsettingexpired = () =>
  import("../optional/dialog-cancelsettingexpiredfile");
const Toast = Swal.mixin({
  toast: true,
  position: "top-end",
  showConfirmButton: false,
  timer: 3000,
});
export default {
  props: ["show", "filedata"],
  components: {
    dialogcancelsettingexpired,
  },
  mixins: [validationMixin],
  validations: {
    alertemail: {
      required,
      emailList
    },
  },
  data() {
    return {
      data: [],
      listdata: [],
      alertemail: "",
      alertbymail: false,
      alertbychat: false,
      processdelete: false,
      opendialogunsetting: false,
      modelsetexpiredfile: false,
      modelsetbeforeexpiredfile: false,
      setexpiredfile: "",
      setbeforeexpiredfile: "",
      datenow: "",
      dateplusone: "",
      settime: "",
      time: "",
      timebeforeexpiration: "",
      max: "",
      switchset: false,
      setdate: "กำหนดวัน",
      checkbtnexfile: false,
      listusers: [],
      one_id: [],
      permission: [],
      page: 1,
      size: 5,
      disablescrolling: false,
      opemdialogchecksendtoonechatmulti: false,
      checkloopclosedialog: false,
      checkfriend: [],
      choosefriend: [],
      listfriend: [],
      filename: "",
      receiver: "",
      subjectmail: "",
      createprogress: false,
      content: "",
      havefriend: true,
      loadingfriends: true,
    };
  },
  computed: {
    ...mapState([
      "username",
      "authorize",
      "account_active",
      "color",
      "role_level",
    ]),
    ...mapState({ processloader: "loading" }),
    ...mapGetters([
      "dataUsername",
      "dataAuthorize",
      "dataAccountActive",
      "dataAccesstoken",
      "dataBusinessProfile",
      "dataCitizenProfile",
      "dataDepartmentAccessId",
      "dataAccountId",
    ]),
    resolutionScreen() {
      switch (this.$vuetify.breakpoint.name) {
        case "xs":
          return 220;
        case "sm":
          return 400;
        case "md":
          return 500;
        case "lg":
          return 600;
        case "xl":
          return 800;
      }
    },
    emailError() {
      const errors = []
      if (!this.$v.alertemail.$dirty) return errors
      !this.$v.alertemail.required && errors.push('โปรดระบุอีเมล / Please fill Email')
      !this.$v.alertemail.emailList && errors.push('ข้อมูลไม่ถูกต้อง / Invalid data')
      return errors
    },
    //  watch: {
    //   //  setexpiredfile(){
    //   //    this.setbeforeexpiredfile = ''
    //   //  }
    //  },
    shower: {
      get() {
        if (this.show === true) {
          console.log("businesss_id", this.dataAccountActive);

          this.check_btnswith();
          let day_;
          console.log("filedata", this.filedata);
          // split ค่าวันเพื่อ +1 วันเนื่องจากตั้งค่าวันหมดอายุวันปัจจุบันไม่ได้
          let date = format(new Date(), "yyyy-MM-dd");
          let datesplit = date.split("-");
          console.log("datesplit", datesplit);
          console.log("datasplit", parseInt(datesplit[2]));
          let day = parseInt(datesplit[2]) + 1;
          console.log("day", day);
          if (day < 10) {
            day_ = "0" + day;
          } else {
            day_ = day;
          }
          console.log("day_", day_);
          this.datenow = datesplit[0] + "-" + datesplit[1] + "-" + day_;
          console.log("this.datenow", this.datenow);
          this.time = this.filedata.expiration_time;

          if (
            this.filedata.expiration_time === "00000000000000" ||
            this.filedata.expiration_time === "" ||
            this.filedata.expiration_time === undefined ||
            this.filedata.expiration_time === null ||
            this.filedata.expiration_time === "-"
          ) {
            // this.max = this.setexpiredfile;
            this.setexpiredfile = "";
            console.log("filedataaa", this.filedata);
            this.switchset = false;
          } else {
            this.setexpiredfile = this.fn_showdatetime(
              this.filedata.expiration_time
            ).toString();
            this.switchset = true;
            this.max = this.fn_showdatetime(
              this.filedata.expiration_time
            ).toString();
            // console.log("this.max ====",this.max);
          }

          if (
            this.filedata.warning_expiration_time === "0000000" ||
            this.filedata.warning_expiration_time === "000000" ||
            this.filedata.warning_expiration_time === "00000000000000" ||
            this.filedata.warning_expiration_time === "" ||
            this.filedata.warning_expiration_time === undefined ||
            this.filedata.warning_expiration_time === null ||
            this.filedata.warning_expiration_time === "-"
          ) {
            this.setbeforeexpiredfile = "";
            // this.switchsetbeforeexfile = false;
          } else {
            this.setbeforeexpiredfile = this.fn_showdatetime(
              this.filedata.warning_expiration_time
            ).toString();
            // this.switchsetbeforeexfile = true;
            this.timebeforeexpiration = this.filedata.warning_expiration_time;
          }
        }
        return this.show;
      },
    },
    // ความกว้างของ dialog ของ ipad และ mobile
    maxWidthOnMobile() {
      if(this.resolutionScreen >= 400) {
        return 600
      } else {
        return 346
      }
    },
  },
  watch: {
    show: function(val) {
      if (val === true) {
        // this.fn_getuser();
        let filename = this.filedata.file_name.split(".");
        console.log(filename);
        let i;
        let namewithoutextension = "";
        for (i = 0; i < filename.length; i++) {
          if (i !== filename.length - 1) {
            namewithoutextension += filename[i];
          } else if (filename.length - 1 === 0) {
            namewithoutextension += filename[i];
          }
        }
        this.filename = namewithoutextension;

        if (this.filedata.file_type === "folder") {
          this.listusers = [];
          this.permission = [];
          this.fn_getuser();
          // this.$emit("closesendonechat");
        } else {
          this.listusers = [];
          this.permission = [];
          this.fn_getuser();
        }
        // this.$emit('closeDrag')
      } else {
        this.$emit("openDrag");
      }
    },
  },
  methods: {
    handleCheckboxChange() {
      if (!this.alertbymail && this.alertemail.length === 0) {
        this.$v.alertemail.$reset();
      }
    },
    //เคลียร์ ช่องวันก่อนหมดอายุ ถ้าไม่เลือกวันหมดอายุ
    clearbtn() {
      this.setexpiredfile = "";
      this.setbeforeexpiredfile = "";
    },
    //เช็ค disable swith วันหมดอายุ
    check_btnswith() {
      if (this.filedata.status_expired === "3") {
        this.checkbtnexfile = true;
      } else {
        this.checkbtnexfile = false;
      }
    },
    //เช็ค max วันก่อนหมดอายุที่สามารถเลือกได้
    fn_datebefore(value) {
      console.log("value======", value);
      if (value === null) {
        return "0000-00-00";
      } else {
        let dateplus = value.split("-");
        let day = parseInt(dateplus[2]) - 1;
        let day_ = day >= 10 ? `${day}` : `0${day}`;
        let date = dateplus[0] + "-" + dateplus[1] + "-" + day_;
        // console.log("dateplus",dateplus);
        // console.log("day",day);
        // console.log("day_",day_);
        // console.log('date+++', date)
        return `${date}`;
      }
    },
    //ปิด dialog
    close_dialogsetting() {
      this.$emit("closedialog");
    },

    // emit กลับไป reload หน้าหลัก
    exitreload() {
      this.$emit("reload");
    },

    // แปลงค่าวันที่โชว์
    fn_showdatetime(_datetime) {
      console.log("_datetime fn_showdatetime", _datetime);
      if (_datetime === "" || _datetime === "-" || _datetime === undefined) {
        return "-";
      }
      // else if(_datetime === "-"){
      //   _datetime = "00000000"
      //   let dateyear = _datetime.split("")[0] + _datetime.split("")[1] + _datetime.split("")[2] + _datetime.split("")[3];
      //   let datemonth = _datetime.split("")[4] + _datetime.split("")[5];
      //   let dateday = _datetime.split("")[6] + _datetime.split("")[7];

      //   return dateyear + "-" + datemonth + "-" + dateday;
      // }
      else {
        if (_datetime === "000000") {
          _datetime = "00000000";
        }
        let dateyear =
          _datetime.split("")[0] +
          _datetime.split("")[1] +
          _datetime.split("")[2] +
          _datetime.split("")[3];
        let datemonth = _datetime.split("")[4] + _datetime.split("")[5];
        let dateday = _datetime.split("")[6] + _datetime.split("")[7];

        return dateyear + "-" + datemonth + "-" + dateday;
      }
    },

    //แปลงค่าวันที่ส่ง api
    fn_formatdatetime(_datetime) {
      console.log("_datetime fn_formatdatetime", _datetime);
      if (
        _datetime === "" ||
        _datetime === "-" ||
        _datetime === undefined ||
        _datetime === null
      ) {
        return "000000";
      } else {
        let dateyear =
          _datetime.split("")[0] +
          _datetime.split("")[1] +
          _datetime.split("")[2] +
          _datetime.split("")[3];
        let datemonth = _datetime.split("")[5] + _datetime.split("")[6];
        let dateday = _datetime.split("")[8] + _datetime.split("")[9];

        return dateyear + datemonth + dateday + "000000";
      }
    },
    checksetexpired() {
      this.page = 1;
      if (
        this.filedata.file_permission_2 === "04" ||
        this.filedata.file_permission_2 === "05" ||
        this.filedata.file_permission_2 === "06"
      ) {
        this.fn_setexfile();
      } else {
        Toast.fire({
          icon: "error",
          title: "ไม่สามารถตั้งค่าวันหมดอายุได้เนื่องจากสิทธิ์เข้าถึง",
        });
      }
    },
    //ตั้งค่าวันหมดอายุ
    async fn_setexfile() {
      // check validate
      if (this.alertbymail && this.$v.alertemail.$invalid){
        this.$v.$touch();
        return
      }
      this.processdelete = true;
      console.log("this.alertbymail", this.alertbymail);
      console.log("this.alertemail", this.alertemail);

      if (this.alertbymail == true || this.alertbychat == true) {
        this.fn_alertbymailorchat();
        //// if(this.alertemail === ''){
        ////   Toast.fire({
        ////     icon: "error",
        ////     title: "กรุณากรอกอีเมลที่ต้องการให้แจ้งเตือน",
        ////   });
        ////   this.processdelete = false;
        //// }
        //// }else{
        // console.log("this.filedata+++", this.filedata);
        // if (this.filedata.file_type === "folder") {
        //   let payload = {
        //     folder_id: this.filedata.file_id,
        //     account_id: this.dataAccountId,
        //     expiration_time:
        //       this.fn_formatdatetime(this.setexpiredfile) === "000000"
        //         ? "00000000000000"
        //         : this.fn_formatdatetime(this.setexpiredfile),
        //     warning_expiration_time:
        //       this.fn_formatdatetime(this.setexpiredfile) === "000000"
        //         ? "0000000"
        //         : this.setexpiredfile === ""
        //         ? "0000000"
        //         : this.fn_formatdatetime(this.setbeforeexpiredfile),
        //   };
        //   console.log("payfolder", payload);
        //   let auth = await gbfGenerate.generateToken();
        //   this.axios
        //     .post(
        //       process.env.VUE_APP_SERVICE_UPDATE_FOLDER +
        //         "/api/v2/set_folder/expiration_time",
        //       payload,
        //       { headers: { Authorization: auth.code } }
        //     )
        //     .then((response) => {
        //       if (response.data.status === "OK") {
        //         this.settime = this.setexpiredfile;
        //         Toast.fire({
        //           icon: "success",
        //           title: this.$t("expiredrightclick.setexpiredsuccess"),
        //         });
        //         this.processdelete = false;
        //         this.$emit("closedialog");
        //         this.$emit("reload");
        //         this.fn_alertbymailorchat();
        //       } else {
        //         Toast.fire({
        //           icon: "error",
        //           title: response.data.errorMessage,
        //         });
        //         this.processdelete = false;
        //       }
        //     })
        //     .catch((error) => {
        //       this.processdelete = false;
        //       this.loaddataprogress = false;
        //       console.log(error);
        //       Toast.fire({
        //         icon: "error",
        //         title: error,
        //       });
        //     });
        // } else {
        //   let payload = {
        //     file_id: this.filedata.file_id,
        //     account_id: this.dataAccountId,
        //     expiration_time:
        //       this.fn_formatdatetime(this.setexpiredfile) === "000000"
        //         ? "00000000000000"
        //         : this.fn_formatdatetime(this.setexpiredfile),
        //     warning_expiration_time:
        //       this.fn_formatdatetime(this.setexpiredfile) === "000000"
        //         ? "0000000"
        //         : this.fn_formatdatetime(this.setbeforeexpiredfile),
        //   };
        //   console.log("payfile", payload);
        //   let auth = await gbfGenerate.generateToken();
        //   this.axios
        //     .post(
        //       process.env.VUE_APP_SERVICE_UPDATE_FILE +
        //         "/api/v2/set_file/expiration_time",
        //       payload,
        //       { headers: { Authorization: auth.code } }
        //     )
        //     .then((response) => {
        //       if (response.data.status === "OK") {
        //         this.settime = this.setexpiredfile;
        //         Toast.fire({
        //           icon: "success",
        //           title: this.$t("expiredrightclick.setexpiredsuccess"),
        //         });
        //         this.processdelete = false;
        //         this.$emit("closedialog");
        //         this.$emit("reload");
        //         this.fn_alertbymailorchat();
        //       } else {
        //         Toast.fire({
        //           icon: "error",
        //           title: response.data.errorMessage,
        //         });
        //         this.processdelete = false;
        //       }
        //     })
        //     .catch((error) => {
        //       this.processdelete = false;
        //       this.loaddataprogress = false;
        //       console.log(error);
        //       Toast.fire({
        //         icon: "error",
        //         title: error,
        //       });
        //     });
        // }
        //// }
      } else {
        console.log("this.filedata+++", this.filedata);
        if (this.filedata.file_type === "folder") {
          let payload = {
            folder_id: this.filedata.file_id,
            account_id: this.dataAccountId,
            expiration_time:
              this.fn_formatdatetime(this.setexpiredfile) === "000000"
                ? "00000000000000"
                : this.fn_formatdatetime(this.setexpiredfile),
            warning_expiration_time:
              this.fn_formatdatetime(this.setexpiredfile) === "000000"
                ? "0000000"
                : this.setexpiredfile === ""
                ? "0000000"
                : this.fn_formatdatetime(this.setbeforeexpiredfile),
            type: []
          };
          console.log("payfolder", payload);
          let auth = await gbfGenerate.generateToken();
          this.axios
            .post(
              process.env.VUE_APP_SERVICE_UPDATE_FOLDER +
                "/api/v2/set_folder/expiration_time",
              payload,
              { headers: { Authorization: auth.code } }
            )
            .then((response) => {
              if (response.data.status === "OK") {
                this.settime = this.setexpiredfile;
                Toast.fire({
                  icon: "success",
                  title: this.$t("expiredrightclick.setexpiredsuccess"),
                });
                this.processdelete = false;
                this.$emit("closedialog");
                this.$emit("reload");
                // if(this.alertbymail == true){
                //   this.fn_alertbymailorchat();
                // }
              } else {
                Toast.fire({
                  icon: "error",
                  title: response.data.errorMessage,
                });
                this.processdelete = false;
              }
            })
            .catch((error) => {
              this.processdelete = false;
              this.loaddataprogress = false;
              console.log(error);
              Toast.fire({
                icon: "error",
                title: error,
              });
            });
        } else {
          let payload = {
            file_id: this.filedata.file_id,
            account_id: this.dataAccountId,
            expiration_time:
              this.fn_formatdatetime(this.setexpiredfile) === "000000"
                ? "00000000000000"
                : this.fn_formatdatetime(this.setexpiredfile),
            warning_expiration_time:
              this.fn_formatdatetime(this.setexpiredfile) === "000000"
                ? "0000000"
                : this.fn_formatdatetime(this.setbeforeexpiredfile),
            type: []
          };
          console.log("payfile", payload);
          let auth = await gbfGenerate.generateToken();
          this.axios
            .post(
              process.env.VUE_APP_SERVICE_UPDATE_FILE +
                "/api/v2/set_file/expiration_time",
              payload,
              { headers: { Authorization: auth.code } }
            )
            .then((response) => {
              if (response.data.status === "OK") {
                this.settime = this.setexpiredfile;
                Toast.fire({
                  icon: "success",
                  title: this.$t("expiredrightclick.setexpiredsuccess"),
                });
                this.processdelete = false;
                this.$emit("reload");
                this.$emit("closedialog");
                // this.fn_alertbymailorchat();
              } else {
                Toast.fire({
                  icon: "error",
                  title: response.data.errorMessage,
                });
                this.processdelete = false;
              }
            })
            .catch((error) => {
              this.processdelete = false;
              this.loaddataprogress = false;
              console.log(error);
              Toast.fire({
                icon: "error",
                title: error,
              });
            });
        }
      }
    },
    // แจ้งเตือนวันหมดอายุ
    async fn_alertbymailorchat() {
      // console.log("alertemail",this.alertemail);
      // this.data = this.alertemail.split(",")
      // console.log("data",this.data);
      // // // console.log("data.lenght",this.data.lenght);
      // for(let i=0; i <= this.data.lenght; i++){
      //   this.listdata.push(this.data[i])
      // console.log("listdata",this.listdata);
      // }
      console.log("iddddddddddd");
      let list = [];
      for (let i = 0; i < this.checkfriend.length; i++) {
        this.one_id.push(this.checkfriend[i].oneid_user_id);
      }
      console.log("iddddddddddd", this.one_id);
      if (this.alertbymail == true) {
        list.push({
          type_notification: "e-mail",
          receiver: this.alertemail.split(","),
        });
      }

      if (this.alertbychat == true) {
        list.push({
          type_notification: "one-chat",
          receiver: this.one_id,
        });
      }
      console.log("type++++++++++++++++++++", list);

      if (this.filedata.file_type === "folder") {
        let payload = {
          account_id: this.dataAccountId,
          folder_id: this.filedata.file_id,
          expiration_time:
            this.fn_formatdatetime(this.setexpiredfile) === "000000"
              ? "00000000000000"
              : this.fn_formatdatetime(this.setexpiredfile),
          warning_expiration_time:
            this.fn_formatdatetime(this.setexpiredfile) === "000000"
              ? "0000000"
              : this.setexpiredfile === ""
              ? "0000000"
              : this.fn_formatdatetime(this.setbeforeexpiredfile),
          // warning_expiration_time: this.filedata.warning_expiration_time,
          // expiration_time: this.filedata.expiration_time,
          type: list,

          // type: [
          //     {
          //         "type_notification": "e-mail",
          //         "receiver":this.alertemail.split(',')
          //         // "receiver": this.alertemail.split(',').map(item => item.trim()),
          //     },
          //     // {
          //     //     "type_notification": "one-chat",
          //     //     "receiver": ["25054802464", "82342910924841402"]
          //     // }
          // ]
        };
        console.log("payfolder", payload);
        let auth = await gbfGenerate.generateToken();
        this.axios
          .post(
            process.env.VUE_APP_SERVICE_DOWNLOAD_API +
              "/api/v2/set_folder/expiration_time",
            payload,
            { headers: { Authorization: auth.code } }
          )
          .then((response) => {
            if (response.data.status === "OK") {
              this.settime = this.setexpiredfile;
              Toast.fire({
                icon: "success",
                title: this.$t("expiredrightclick.setexpiredsuccess"),
              });
              this.processdelete = false;
              // this.$emit("closedialog");
              // this.$emit("reload");
              // if(this.alertbymail == true){
              //   this.fn_alertbymailorchat();
              // }
            } else {
              Toast.fire({
                icon: "error",
                title: response.data.errorMessage,
              });
              this.processdelete = false;
            }
          })
          .catch((error) => {
            this.processdelete = false;
            this.loaddataprogress = false;
            console.log(error);
            Toast.fire({
              icon: "error",
              title: error,
            });
          });
      } else {
        let payload = {
          account_id: this.dataAccountId,
          file_id: this.filedata.file_id,
          expiration_time:
            this.fn_formatdatetime(this.setexpiredfile) === "000000"
              ? "00000000000000"
              : this.fn_formatdatetime(this.setexpiredfile),
          warning_expiration_time:
            this.fn_formatdatetime(this.setexpiredfile) === "000000"
              ? "0000000"
              : this.setexpiredfile === ""
              ? "0000000"
              : this.fn_formatdatetime(this.setbeforeexpiredfile),
          // warning_expiration_time: this.filedata.warning_expiration_time,
          // expiration_time: this.filedata.expiration_time,
          type: list,
        };
        console.log("payfile", payload);
        let auth = await gbfGenerate.generateToken();
        this.axios
          .post(
            process.env.VUE_APP_SERVICE_DOWNLOAD_API +
              "/api/v2/set_file/expiration_time",
            payload,
            { headers: { Authorization: auth.code } }
          )
          .then((response) => {
            if (response.data.status === "OK") {
              this.settime = this.setexpiredfile;
              Toast.fire({
                icon: "success",
                title: this.$t("expiredrightclick.setexpiredsuccess"),
              });
              this.processdelete = false;
              this.$emit("closedialog");
              this.$emit("reload");
              // if(this.alertbymail == true){
              //   this.fn_alertbymailorchat();
              // }
            } else {
              Toast.fire({
                icon: "error",
                title: response.data.errorMessage,
              });
              this.processdelete = false;
            }
          })
          .catch((error) => {
            this.processdelete = false;
            this.loaddataprogress = false;
            console.log(error);
            Toast.fire({
              icon: "error",
              title: error,
            });
          });
      }
    },
    // แจ้งเตือนวันหมดอายุ(ดึงข้อมูล User One Chat)
    async fn_getuser() {
      this.listusers = [];
      // this.permission= [];
      this.disablescrolling = false;
      this.loaddataprogress = true;
      console.log("this.page", this.page);

      let cal_limit = this.size;
      let cal_offset = this.size * (this.page++ - 1);
      let total = "";
      console.log("cal_offset", cal_offset);
      console.log("cal_limit", cal_limit);
      if (cal_offset == 0) {
        this.permission = [];
      }

      console.log("this.dataAccountActive", this.dataAccountActive);
      console.log("this.dataAccountId", this.dataAccountId);
      console.log("this.filedata.file_type", this.filedata.file_type);
      let payload = {
        type:
          this.filedata.file_type === "folder"
            ? this.filedata.file_type
            : "file",
        id: this.filedata.file_id,
        account_id: this.dataAccountId,
        business_id: this.dataAccountActive.business_info.business_id,
        limit: cal_limit,
        offset: cal_offset,
      };
      console.log("payload", payload);
      let auth = await gbfGenerate.generateToken();
      this.axios
        .post(
          process.env.VUE_APP_SERVICE_DOWNLOAD_API +
            "/api/notification/get_user_account",
          payload,
          { headers: { Authorization: auth.code } }
        )
        .then((response) => {
          if (response.data.status === "OK") {
            console.log(response);
            this.listusers = response.data.result;
            total = response.data.total;
            for (let i = 0; i < this.listusers.length; i++) {
              this.permission.push(this.listusers[i]);
            }
            // this.fn_loadfileandfolder_scrolling()
            this.loadingfriends = false;
            console.log("listuserssssssss", this.listusers);
            console.log("total", total);
            this.disablescrolling = total <= this.permission.length;
            console.log("disablescrollinggggggg", this.disablescrolling);
          } else {
            Toast.fire({
              icon: "error",
              title: response.data.errorMessage,
            });
            this.processdelete = false;
          }
        })
        .catch((error) => {
          this.processdelete = false;
          this.loaddataprogress = false;
          console.log(error);
          Toast.fire({
            icon: "error",
            title: error,
          });
        });
    },
    async summitsendonechat() {
      if (this.checkfriend.length == 0) {
        Toast.fire({
          icon: "error",
          title: this.$t("sentFileOnechat.addreceiver"),
        });
      } else {
        this.opemdialogchecksendtoonechatmulti = true;
      }
    },

    //เปิดdialog ยกเลิกการตั้งค่าวันหมดอายุ
    fn_cancelexfile(_switchset) {
      console.log(_switchset);
      if (_switchset === false) {
        this.opendialogunsetting = true;
        // console.log("ปิดจ้าาาา");
        // if (this.filedata.file_type === "folder") {
        //   let payload = {
        //     folder_id: this.filedata.file_id,
        //     account_id: this.dataAccountId,
        //     expiration_time: "00000000000000",
        //   };
        //   console.log("payfolder", payload);
        //   this.axios
        //     .post(
        //       process.env.VUE_APP_SERVICE_DOWNLOAD_API +
        //         "/api/set_folder/expiration_time",
        //       payload
        //     )
        //     .then((response) => {
        //       if (response.data.status === "OK") {
        //         this.settime = this.setexpiredfile;
        //         Toast.fire({
        //           icon: "success",
        //           title: "ยกเลิกตั้งค่าวันหมดอายุสำเร็จ",
        //         });
        //         this.$emit("reload");
        //         this.setexpiredfile = "";
        //         this.$emit("closedialog");
        //       }
        //     })
        //     .catch((error) => {
        //       this.loaddataprogress = false;
        //       console.log(error);
        //       Toast.fire({
        //         icon: "error",
        //         title: error,
        //       });
        //     });
        // } else {
        //   let payload = {
        //     file_id: this.filedata.file_id,
        //     account_id: this.dataAccountId,
        //     expiration_time: "00000000000000",
        //   };
        //   console.log("payfile", payload);
        //   this.axios
        //     .post(
        //       process.env.VUE_APP_SERVICE_DOWNLOAD_API +
        //         "/api/set_file/expiration_time",
        //       payload
        //     )
        //     .then((response) => {
        //       if (response.data.status === "OK") {
        //         this.settime = this.setexpiredfile;
        //         Toast.fire({
        //           icon: "success",
        //           title: "ยกเลิกตั้งค่าวันหมดอายุสำเร็จ",
        //         });
        //         this.$emit("reload");
        //         this.setexpiredfile = "";
        //         this.$emit("closedialog");
        //       }
        //     })
        //     .catch((error) => {
        //       this.loaddataprogress = false;
        //       console.log(error);
        //       Toast.fire({
        //         icon: "error",
        //         title: error,
        //       });
        //     });
        // }
      }
    },
  },
  // mounted(){
  //   this.fn_getuser();
  // }
};
</script>
<style>
#v-textarea-email {
  margin: 4px 10px 0 0;
}
</style>
